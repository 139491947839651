/* General styles for the table */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}


/* Styles for pagination */
.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
}

.pagination li {
    margin: 0 5px;
}

.pagination li a {
    border: 1px solid #ddd;
    padding: 5px 10px;
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
}

.pagination li.active a {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

.pagination li.disabled a {
    color: #ddd;
    cursor: default;
}

/* Responsive styles */
@media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    table {
        border: 0;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border: 1px solid #ccc;
        margin-bottom: 5px;
    }

    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right;
    }

    td:before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        transform: translateY(-50%);
    }

    

    /* Adjust pagination for mobile */
    .pagination {
        flex-wrap: wrap;
    }

    .pagination li {
        margin: 2px;
    }
}

@media (max-width: 480px) {
    td {
        padding-left: 40%;
    }

    td:before {
        left: 5px;
        width: 35%;
    }
}