/* Estilos generales */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: black;

}

.app {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: black;
}

h1 {
  color: #333;
  text-align: center;
}

/* Estilos para el árbol de enlaces */
.link-tree {
  display: flex !important;
    flex-direction: column;
    gap: 10px;
}

.link-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.link-item a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.link-item a:hover {
  color: #ff4500;
}

/* Estilos para dispositivos móviles */
@media only screen and (max-width: 600px) {
  .link-tree {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  }
}