/* Endpoint para todos los celulares */
@media (max-width: 600px) {
    
    .bonificacion{
        transform:translate(80%, 95%) !important;
    }
    .superpuesto {
        font-size: 1.5rem;
    }
}

/* Endpoint para todas las pantallas */
@media (min-width: 601px) {
   
    .bonificacion{
        transform:translate(190%, 95%) !important;
    }
    .superpuesto {
        font-size: 2.5rem;
    }
}